<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Grupo') : $t('Novo Grupo')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveGroup"
      @hidden="hideSidebar"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="groupForm"
            name="form"
          >
            <FormulateInput
              id="product-group-name"
              v-model="localGroup.name"
              type="text"
              class="required input-uppercase"
              :label="$t('Nome')"
              validation="required"
            />
            <FormulateInput
              id="product-group-hectoliters"
              v-model="localGroup.hectoliters"
              type="switch"
              :label="$t('Contabilizar hectolitros')"
              :instruction="
                $t(
                  'Quando habilitado, os produtos deste grupo terão um atributo que indica a quantidade de mililitros da unidade, para que se possa fazer a contabilização de hectolitros comercializados.'
                )
              "
            />
          </FormulateForm>
          <FormulateInput
            id="product-group-image-local"
            v-model="imageLocal"
            type="uploader"
            deletable="true"
            max-files="1"
            :meta="false"
            :label="$t('Imagem')"
          />
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar } from '@/views/components'
import { uploader } from '@/mixins'
import { mapActions } from 'vuex'

const getInitialLocalGroup = () => ({
  id: null,
  name: '',
  hectoliters: false,
  active: null,
})

export default {
  components: {
    ESidebar,
  },

  mixins: [uploader],

  props: {
    groupId: {
      type: Number,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localGroupId: null,
      localGroup: getInitialLocalGroup(),
      imageLocal: [],
    }
  },

  watch: {
    group(val) {
      this.localGroup = val
      this.imageLocal = val?.image ? [this.getFileInstance(val.image)] : []
    },
    async groupId(val) {
      this.localGroupId = val
    },
    async localGroupId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: localGroup } = await this.$http.get(
            `/api/product-groups/${this.localGroupId}`
          )
          this.localGroup = localGroup
          this.imageLocal = localGroup?.image ? [this.getFileInstance(localGroup.image)] : []
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.localGroup = {}
      }
    },
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),

    showCreate() {
      this.localGroup = getInitialLocalGroup()
      this.imageLocal = []
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localGroupId = id
        if (item) this.localGroup = item
        this.edit = true
        this.showSidebar = true
      }
    },

    hideSidebar() {
      this.localGroup = getInitialLocalGroup()
      this.localGroupId = null
      this.showSidebar = false
    },

    async saveGroup() {
      this.$refs.groupForm.showErrors()
      if (this.$refs.groupForm.isValid) {
        try {
          this.saving = true

          const [imageUploadResult] = await this.upload({
            files: this.imageLocal,
            destinationPath: 'images/productGroups',
          })

          if (imageUploadResult) {
            this.imageLocal = [this.getFileInstance(imageUploadResult.url)]
          }

          const body = {
            id: this.localGroup.id,
            name: this.localGroup.name,
            hectoliters: this.localGroup.hectoliters,
            image: imageUploadResult?.url,
            active: this.localGroup.active,
          }
          const result = await this.$http({
            url: '/api/product-groups',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.comboFetchDomainData('product-groups')
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.$emit('save', result.data)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>

<style></style>
